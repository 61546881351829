import React, { useState } from "react"
import { useForm } from "react-hook-form"

import "./style.scss"



const Form = ({ form }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [sendingPass, setsendingPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const onSubmit = (values, e) => {
    if (loading) return;
    const formData = new FormData()
    formData.append("your-email", values.email)
    formData.append("your-Fname", values.fname)
    formData.append("your-Lname", values.lname)
    formData.append("your-message", values.message)
    // 
    setLoading(true)
    return fetch(process.env.wordPressUrl + "/wp-json/contact-form-7/v1/contact-forms/" + formId + '/feedback', {
      method: "post",
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        setLoading(false)
        setsendingPass(true)
        e.target.reset()
        setTimeout(() => {
          setsendingPass(false)
        }, 3500);
      }
    })
  }

  const { buttonText, emailLabel, fnameLabel, formId, formTitle, lnameLabel, messageLabel, textSending, textSuccess, errorInput } = form;
  return (
    <div className="contactForm">
      <div className="contactForm__wrapper">

        <h2 className="heading heading--dash heading--bigNormal">
          {formTitle}
        </h2>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__input">
            <label className="form__label" htmlFor="fname">
              {fnameLabel}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="fname"
              type="text"
              {...register("fname", {
                required: true,
              })}
            />
            <span className="form__error">{errors.fname && errorInput}</span>
          </div>
          {/*  */}
          <div className="form__input">
            <label className="form__label" htmlFor="lname">
              {lnameLabel}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="lname"
              type="text"
              {...register("lname", {
                required: true,
              })}
            />
            <span className="form__error">{errors.lname && errorInput}</span>
          </div>
          {/*  */}
          <div className="form__input">
            <label className="form__label" htmlFor="email">
              {emailLabel}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="email"
              type="email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }
              })}
            />
            <span className="form__error">{errors.email && errorInput}</span>
          </div>
          {/*  */}
          <div className="form__input">
            <label htmlFor="message" className="form__label">
              {messageLabel}
              <span className="form__label__required">*</span>
            </label>
            <textarea
              id="message"
              {...register("message", {})}
            />
            <span className="form__error">{errors.email && errorInput}</span>
          </div>
          {/*  */}
          <div className="form__button">
            <button type="submit">{buttonText}</button>
          </div>
          {/*  */}
          {loading ? <div className="form__load">{textSending}</div> : ""}
          <div className="form__ok">
            {sendingPass ? (
              <p className="form__thx">{textSuccess}</p>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
export default Form
