import React from "react"
import "./style.scss"
import Img from "gatsby-image"
import Phone from "../../../images/contact_phone.svg"
import { getCookie, removeHost } from "../../../utils/functions"
import { Link } from "gatsby"

const Counselor = ({ counselor, featuredImage }) => {
  const {
    counselorButton,
    counselorDescription,
    counselorTextPhone,
    counselorTitle,
    counselorPhone,
  } = counselor
  return (
    <div className="contactCounselor">
      <div className="contactCounselor__wrapper">
        <h2 className="heading heading--dash heading--bigNormal">
          {counselorTitle}
        </h2>
        <div className="contactCounselor__columns">
          <div className="contactCounselor__columns__item contactCounselor__columns__item--content">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: removeHost(counselorDescription),
              }}
            ></div>
            <div className="contactCounselor__columns__button">
              {getCookie("gatsby-gdpr") ? (
                <a href="#cp-widget" className="btn">
                  {counselorButton}
                </a>
              ) : (
                <Link href="#" className="btn">
                  {counselorButton}
                </Link>
              )}
            </div>
            <p
              className="contactCounselor__columns__para"
              dangerouslySetInnerHTML={{
                __html: removeHost(counselorTextPhone),
              }}
            ></p>
            <div className="contactCounselor__columns__phone">
              <Link
                href={counselorPhone.url}
                target="_blank"
                className="heading heading--bigNormal"
              >
                <Phone />
                {counselorPhone.title}
              </Link>
            </div>
          </div>
          <div className="contactCounselor__columns__item contactCounselor__columns__item--img">
            <div className="contactCounselor__img">
              <Img fluid={featuredImage.imageFile.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Counselor
